import {
  FETCH_SURVEYS,
  PAYMENT_SUCCESS,
  PAYMENT_RESET,
  FETCH_LATEST_SURVEYS,
} from "./../actions/types";

const initialState = {
  loading: true,
  payment: false,
  latestSurveys: [],
  surveys: [],
};

export default function (
  state = initialState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SURVEYS:
      return {
        ...state,
        surveys: payload,
        loading: false,
      };
    case FETCH_LATEST_SURVEYS:
      return {
        ...state,
        latestSurveys: payload,
        loading: false,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        payment: true,
        loading: false,
      };
    case PAYMENT_RESET:
      return {
        ...state,
        payment: false,
        loading: false,
      };

    default:
      return state;
  }
}
