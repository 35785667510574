import React, {
  Component,
} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchSurveys } from "../../actions/survey";

import LoadingComponent from "./../layout/Loading";

class SurveyList extends Component {
  componentDidMount() {
    // if (!this.props.loading) {
    this.props.fetchSurveys();
    // console.log(this.props.surveys);
    // }
  }

  renderSurveys() {
    if (!this.props.surveys.loading) {
      // console.log(
      //   this.props.surveys.surveys
      // );
      return this.props.surveys.surveys
        .reverse()
        .map((survey) => {
          return (
            <Link
              to={`/api/surveys/${survey._id}/status`}
              key={survey._id}
            >
              <div
                key={survey._id}
                className="card blue-grey darken-1 survey"
              >
                <div className="card-content white-text">
                  <span className="card-title ">
                    <i className="fas fa-search-plus"></i>
                    &nbsp;{survey.title}
                  </span>
                  <p className="">
                    {survey.body}
                  </p>
                  <p className="right">
                    <i>
                      Sent On:{" "}
                      {new Date(
                        survey.dateSent
                      ).toLocaleDateString()}
                    </i>
                  </p>
                </div>
                <div className="card-action">
                  {/* <a>Yes: {survey.yes}</a>
                <a>No: {survey.no}</a> */}
                  <div className="former-link">
                    Yes: {survey.yes}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    No: {survey.no}
                  </div>
                </div>
              </div>
            </Link>
          );
        });
    }
    // return <Spinner />;
    return (
      <div
        style={{ alignItems: "center" }}
      >
        <hr />
        <h4>Loading latest surveys</h4>
        <LoadingComponent />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.loading ||
        this.props.asyncReducer
          .loading ? (
          <LoadingComponent />
        ) : (
          <div className="surveys">
            {this.renderSurveys()}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps({
  surveys,
  loading,
  asyncReducer,
}) {
  return {
    surveys,
    loading,
    asyncReducer,
  };
}

export default connect(
  mapStateToProps,
  { fetchSurveys }
)(SurveyList);
