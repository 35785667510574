import React, {
  Component,
  Fragment,
  useEffect,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { Link } from "react-router-dom";
import { fetchFiveSurveys } from "../../actions/survey";
import LoadingComponent from "./../layout/Loading";
import SurveyChartBar from "./SurveyChartBar";
import SurveyChart from "./SurveyChart";
// import asyncReducer from "../../reducers/asyncReducer";

export default function LatestSurvey() {
  // const [loading, setCount] = useState(asyncReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFiveSurveys());
  }, [dispatch]);

  const surveys = useSelector(
    (state) => state.surveys
  );
  const asyncReducer = useSelector(
    (state) => state.asyncReducer
  );

  const renderSurveys = (
    <>
      {surveys.latestSurveys !== [] ? (
        <SurveyChartBar
          surveys={
            surveys.latestSurveys || []
          }
        />
      ) : null}

      <div className="surveys-part">
        {surveys.latestSurveys
          .reverse()
          .map((survey) => {
            return (
              <Link
                to={`/api/surveys/${survey._id}/status`}
                key={survey._id}
              >
                <div
                  key={survey._id}
                  className="card blue-grey darken-1 survey-front-page"
                >
                  <div className="card-content-front-page white-text">
                    <span className="card-title-front-page ">
                      <i className="fas fa-search-plus"></i>
                      &nbsp;
                      {survey.subject}
                    </span>
                    <p className="">
                      {survey.body}
                    </p>

                    <>
                      {survey && (
                        <SurveyChart
                          currentSurvey={
                            survey
                          }
                          firstPage
                        />
                      )}
                    </>
                  </div>
                  <div className="card-action">
                    <div className="former-link">
                      <span className="yes-vote-first-page">
                        Yes:{" "}
                        {survey.yes}
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span className="no-vote-first-page">
                        No: {survey.no}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </>
  );

  const loadingPart = (
    <div
      style={{
        alignItems: "center",
      }}
    >
      <hr />
      <h4>Loading latest surveys</h4>
      <LoadingComponent />
    </div>
  );

  return (
    <div>
      {asyncReducer.loading ||
      surveys.loading
        ? loadingPart
        : renderSurveys}
    </div>
  );
}

// function mapStateToProps({
//   surveys,
//   asyncReducer,
// }) {
//   return { surveys, asyncReducer };
// }

// export default connect(
//   mapStateToProps,
//   {}
// )(LatestSurvey);
