import React, {
  Fragment,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { add5 } from "../../actions/survey";
import Payments from "../surveys/Payments";

const Navbar = ({
  auth: {
    isAuthenticated,
    loading,
    user,
  },
  payment,
  add5,
  logout,
}) => {
  // useEffect(() => {
  //   add5();
  // }, [add5]);

  const authLinks = (
    <Fragment>
      {/* <li>
        <Link to="/profiles">
          Developers
        </Link>
      </li>
      <li>
        <Link to="/posts">Posts</Link>
      </li> */}
      <li className="right center menu-item">
        <a onClick={logout} href="#!">
          <i className="fas fa-sign-out-alt" />
          <span className="hide-sm">
            Logout
          </span>
        </a>
      </li>

      {user && (
        <li className="right menu-item ">
          <span className="hide-sm">
            Credits:
          </span>
          {payment ? (
            <>
              <span
                className={`new badge color-blue badge-mod cur`}
                data-badge-caption={
                  user.credits
                }
              />
            </>
          ) : (
            <span
              className={`new badge color-blue badge-mod`}
              data-badge-caption={
                user.credits
              }
            />
          )}
        </li>
      )}
      <li className="right">
        <Payments />
      </li>
      {/* <li>
        <Link to="/dashboard">
          <i className="fas fa-user" />
          <span className="hide-sm">
            Dashboard
          </span>
        </Link>
      </li> */}
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      {/* <li>
        <Link to="/profiles">
          Developers
        </Link>
      </li> */}

      {/* <li>
        <Payments />
      </li> */}
      {/* <i className='fas fa-sign-out-alt' /> */}
      {/* <span className="hide-sm">
            Payments
          </span> */}
      <li className="right">
        <Link to="/register">
          Register
        </Link>
      </li>
      <li className="right">
        <Link to="/login">Login</Link>
      </li>
    </Fragment>
  );

  return (
    // <nav className="navbar bg-dark">
    // <nav className="nav-wrapper bg-dark">
    //   <ul className="right">
    //     {!loading && (
    //       <Fragment>
    //         {isAuthenticated
    //           ? authLinks
    //           : guestLinks}{" "}
    //       </Fragment>
    //     )}
    //   </ul>
    // </nav>
    <nav className="bg-color">
      <div className="nav-wrapper bg-dark">
        <Link
          to="/"
          className="brand-logo left"
        >
          {/* <i className="fas fa-code" />{" "} */}
          <i className="material-icons icon-brand-logo">
            email
          </i>
          <span className="hide-on-med-and-down">
            Emaily
          </span>
        </Link>
        <ul
          id="nav-mobile"
          className="right "
        >
          {!loading && (
            <Fragment>
              {isAuthenticated
                ? authLinks
                : guestLinks}
            </Fragment>
          )}
        </ul>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  // ptfr
  logout: PropTypes.func.isRequired,
  // ptor
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  payment: state.surveys.payment,
});

export default connect(
  mapStateToProps,
  { logout, add5 }
)(Navbar);