import {
  SURVEY_SENT,
  FETCH_SURVEY,
} from "./../actions/types";

const initialState = {
  loading: true,
  survey: "",
  currentSurvey: null,
};

export default function (
  state = initialState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case SURVEY_SENT:
      return {
        ...state,
        survey: payload,
        loading: false,
      };
    case FETCH_SURVEY:
      return {
        ...state,
        currentSurvey: payload,
        loading: false,
      };

    default:
      return state;
  }
}
