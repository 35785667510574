import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import post from "./post";
import survey from "./survey";
import surveys from "./surveys";
import asyncReducer from "./asyncReducer";
import { reducer as reduxForm } from "redux-form";

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  survey,
  surveys,
  asyncReducer,
  form: reduxForm,
});
