import React, {
  Fragment,
  useEffect,
} from "react";
import withSplashScreen from "./components/withSplashScreen";
import "./App.css";
import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Alert from "./components/layout/Alert";
// import "materialize-css/dist/css/materialize.min.css";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import Dashboard from "./components/dashboard/Dashboard";
import PrivateRoute from "./components/routing/PrivateRoute";
import EditProfile from "./components/profile-form/EditProfile";
import AddExperience from "./components/profile-form/AddExperience";
import Profile from "./components/profile/Profile";
import AddEducation from "./components/profile-form/AddEducation";
import CreateProfile from "./components/profile-form/CreateProfile";
import Profiles from "./components/profiles/Profiles";
import Posts from "./components/posts/Posts";
import Post from "./components/post/Post";
import VotedSurvey from "./components/surveys/VotedSurvey";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import {
  ToastContainer,
  toast,
} from "react-toastify";
import "materialize-css/dist/css/materialize.min.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import SurveyNew from "./components/surveys/SurveyNew";
import Thanks from "./components/layout/Thanks";
import { fetchSurveysDelayed } from "./actions/survey";
import {
  asyncActionFinish,
  asyncActionStart,
} from "./reducers/asyncReducer";
window.axios = axios;

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  // when the state updates 'useEffect' will keep running and be in a constant loop,
  // '[]' means it will only run once
  useEffect(() => {
    store.dispatch(asyncActionStart());
    store.dispatch(loadUser());
    // store.dispatch(
    //   fetchSurveysDelayed()
    // );
    store.dispatch(asyncActionFinish());
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        hideProgressBar
        autoClose={5000}
        pauseOnHover
      />
      <Provider store={store}>
        <Router>
          <Fragment>
            <Navbar />

            <Fragment>
              <div className="landing-bg landing">
                <Route
                  exact
                  path="/"
                  component={Landing}
                  // key={Date.now()}
                />
                <section className="landing">
                  <div className="container login-class">
                    <Alert />
                    <Switch>
                      <Route
                        exact
                        path="/register"
                        component={
                          Register
                        }
                      />
                      <Route
                        exact
                        path="/api/surveys/:surveyId/:vote"
                        component={
                          VotedSurvey
                        }
                      />

                      <Route
                        exact
                        path="/login"
                        component={
                          Login
                        }
                      />
                      <Route
                        exact
                        path="/profiles"
                        component={
                          Profiles
                        }
                      />
                      <Route
                        exact
                        path="/profile/:id"
                        component={
                          Profile
                        }
                      />
                      <Route
                        exact
                        path="/surveys/new"
                        component={
                          SurveyNew
                        }
                      />
                      <PrivateRoute
                        exact
                        path="/dashboard"
                        component={
                          Dashboard
                        }
                      />
                      <PrivateRoute
                        exact
                        path="/create-profile"
                        component={
                          CreateProfile
                        }
                      />
                      <PrivateRoute
                        exact
                        path="/edit-profile"
                        component={
                          EditProfile
                        }
                      />
                      <PrivateRoute
                        exact
                        path="/add-experience"
                        component={
                          AddExperience
                        }
                      />
                      <PrivateRoute
                        exact
                        path="/add-education"
                        component={
                          AddEducation
                        }
                      />
                      <PrivateRoute
                        exact
                        path="/posts"
                        component={
                          Posts
                        }
                      />
                      <PrivateRoute
                        exact
                        path="/posts/:id"
                        component={Post}
                      />
                      <Route
                        exact
                        path="/api/surveys/thanks"
                        component={
                          Thanks
                        }
                      />
                    </Switch>
                  </div>
                </section>
              </div>
            </Fragment>
          </Fragment>
        </Router>
        {/* <button onClick={notify}>
          Notify !
        </button> */}
      </Provider>
    </>
  );
};

export default withSplashScreen(App);
