import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  CLEAR_PROFILE,
  LOGIN_FAIL,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import backend from "./backend";
import { delay } from "./../utils/util";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "./../reducers/asyncReducer";
import { toast } from "react-toastify";

// Load User
export const loadUser = () => async (
  dispatch
) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch(asyncActionStart());
  try {
    const res = await axios.get(
      `${backend}/api/auth`
    );

    dispatch({
      type: USER_LOADED,
      // payload is 'user'
      payload: res.data,
    });
    dispatch(asyncActionFinish());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = ({
  name,
  email,
  password,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type":
        "application/json",
    },
  };

  const body = JSON.stringify({
    name,
    email,
    password,
  });

  try {
    const res = await axios.post(
      `${backend}/api/users`,
      body,
      config
    );
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors =
      err.response.data.errors;

    if (errors) {
      errors.forEach((error) =>
        dispatch(
          setAlert(error.msg, "danger")
        )
      );
    }
    dispatch({
      type: REGISTER_FAIL,
    });
    asyncActionError(
      "Something went wrong"
    );
    toast.error(
      "Sorry, we cannot register you in right now, try again later"
    );
  }
};

// Login User
export const login = (
  email,
  password
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type":
        "application/json",
      "Access-Control-Allow-Origin":
        "*",
    },
  };

  const body = JSON.stringify({
    email,
    password,
  });

  try {
    console.log(`${backend}/api/auth`);
    console.log(config);
    const res = await axios.post(
      `${backend}/api/auth`,
      body,
      config
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    toast.success(
      "You are logged in. Welcome back."
    );
  } catch (err) {
    console.log(err);
    const errors =
      err.response.data.errors;

    if (errors !== undefined) {
      errors.forEach((error) => {
        // dispatch(
        //   setAlert(error.msg, "danger")
        // );

        toast.error(error.msg);
      });
    }
    dispatch({
      type: LOGIN_FAIL,
    });
    asyncActionError(
      "Something went wrong"
    );
  }
};

// LOgout / Clear profile
export const logout = () => async (
  dispatch
) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};
