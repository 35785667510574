import React, {
  Component,
} from "react";
import Chart from "chart.js";
import classes from "./SurveyChart.module.css";

export default class SurveyChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext(
      "2d"
    );

    const {
      yes,
      no,
    } = this.props.currentSurvey;
    // console.log(this.props.currentSurvey);

    // new Chart(myChartRef, {
    //   type: "line",
    //   data: {
    //     //Bring in data
    //     labels: ["Jan", "Feb", "March"],
    //     datasets: [
    //       {
    //         label: "Sales",
    //         data: [86, 67, 91],
    //       },
    //     ],
    //   },
    //   options: {
    //     //Customize chart options
    //   },
    // });

    var COLORS = [
      "#4dc9f6",
      "#f67019",
      "#f53794",
      "#537bc4",
      "#acc236",
      "#166a8f",
      "#00a950",
      "#58595b",
      "#8549ba",
    ];

    new Chart(myChartRef, {
      type: "pie",
      data: {
        //Bring in data
        labels: [
          `Yes: ${yes}`,
          `No: ${no}`,
        ],
        datasets: [
          {
            data: [
              // 10,
              // 5,
              // 30,
              // 2,
              yes,
              no,
            ],
            backgroundColor: [
              COLORS[0],
              COLORS[1],
              COLORS[2],
              COLORS[3],
              COLORS[4],
            ],
            label: "Dataset 1",
          },
          // {
          //   data: [
          //     // 4
          //     // 3,
          //     // 20,
          //     // 5,
          //     // 18,
          //     // 9
          //   ],
          //   backgroundColor: [
          //     COLORS[0],
          //     COLORS[1],
          //     COLORS[2],
          //     COLORS[3],
          //     COLORS[4],
          //   ],
          //   borderWidth: 1,
          //   label: 'Dataset 2'
          // }
        ],
      },
      options: {
        //Customize chart options
      },
    });
  }
  render() {
    return (
      <div
        className={
          this.props.firstPage
            ? classes.graphContainerFirstPage
            : classes.graphContainer
        }
      >
        <canvas
          id="myChart"
          ref={this.chartRef}
        />
      </div>
    );
  }
}
