import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

const Thanks = ({
  auth: { isAuthenticated, loading },
  logout,
}) => {
  const contentLoaded = (
    <>
      <p>Thank you for voting.</p>
    </>
  );

  return (
    <section className="landing">
      <div className="dark-overlay">
        <div className="landing-inner">
          {!loading && (
            <Fragment>
              {isAuthenticated
                ? contentLoaded
                : contentLoaded}{" "}
            </Fragment>
          )}
        </div>
      </div>
    </section>
  );
};

Thanks.propTypes = {
  // ptfr
  logout: PropTypes.func.isRequired,
  // ptor
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { logout }
)(Thanks);
