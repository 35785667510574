import { connect } from "react-redux";
import React, {
  Component,
} from "react";
import StripeCheckout from "react-stripe-checkout";
import {handleStripeToken} from '../../actions/profile'

class Payments extends Component {
  
  render() {
    return (
      <StripeCheckout
      name="Emaily"
      description="5$ for 5 email credits"
        amount={500} // 5$
        token={(token) =>
          this.props.handleStripeToken(token)
        }
        stripeKey={
          process.env
            .REACT_APP_STRIPE_PUBLISHABLE_KEY
        }
      >
        <button className="btn btn-primary">Add credits</button>
        </StripeCheckout>
    );
  }
}

export default connect(null,{ handleStripeToken}) (Payments);
