import React, {
  Component,
} from "react";
import Chart from "chart.js";
import classes from "./SurveyChart.module.css";

export default class SurveyChartBar extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext(
      "2d"
    );

    console.log(this.props);

    // new Chart(myChartRef, {
    //   type: "line",
    //   data: {
    //     //Bring in data
    //     labels: ["Jan", "Feb", "March"],
    //     datasets: [
    //       {
    //         label: "Sales",
    //         data: [86, 67, 91],
    //       },
    //     ],
    //   },
    //   options: {
    //     //Customize chart options
    //   },
    // });

    var COLORS = [
      "#4dc9f6",
      "#f67019",
      "#f53794",
      "#537bc4",
      "#acc236",
      "#166a8f",
      "#00a950",
      "#58595b",
      "#8549ba",
    ];

    new Chart(myChartRef, {
      type: "bar",
      data: {
        //Bring in data
        labels: [
          `${this.props.surveys[0].subject}`,
          `${this.props.surveys[1].subject}`,
          `${this.props.surveys[2].subject}`,
          `${this.props.surveys[3].subject}`,
          `${this.props.surveys[4].subject}`,
        ],
        datasets: [
          {
            data: [
              // 10,
              // 5,
              // 30,
              // 2,
              this.props.surveys[0]
                .yes +
                this.props.surveys[0]
                  .no,
              this.props.surveys[1]
                .yes +
                this.props.surveys[1]
                  .no,
              this.props.surveys[2]
                .yes +
                this.props.surveys[2]
                  .no,
              this.props.surveys[3]
                .yes +
                this.props.surveys[3]
                  .no,
              this.props.surveys[4]
                .yes +
                this.props.surveys[4]
                  .no,
            ],
            backgroundColor: [
              COLORS[0],
              COLORS[1],
              COLORS[2],
              COLORS[3],
              COLORS[4],
            ],
            label: [
              "Votes from the latest 5 surveys",
            ],
            yAxisID: 0,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }
  render() {
    if (this.props.surveys !== []) {
      return (
        <div
          className={
            classes.graphContainer
          }
        >
          <canvas
            id="myChart"
            ref={this.chartRef}
          />
        </div>
      );
    }
    return;
  }
}
