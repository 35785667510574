import React, {
  Component,
} from "react";
import {
  reduxForm,
  Field,
} from "redux-form";
import _ from "lodash";
import SurveyField from "./SurveyField";
import { Link } from "react-router-dom";
import validateEmails from "../../utils/validateEmails";
import formFields from "./formFields";

class SurveyForm extends Component {
  renderFields() {
    return _.map(
      formFields,
      ({ label, name }) => {
        return (
          <Field
            component={SurveyField}
            type="text"
            label={label}
            name={name}
            key={name}
          />
        );
      }
    );
  }

  render() {
    return (
      <div className="container login-class">
        <h4 className="subtitle">
          Please fill in the Survey
          properties
        </h4>

        <form
          className="form"
          onSubmit={this.props.handleSubmit(
            this.props.onSurveySubmit
          )}
        >
          <div className="form-group">
            {this.renderFields()}
          </div>

          <Link
            className="btn btn-secondary my-1"
            to="/"
          >
            Cancel
          </Link>
          <button
            type="submit"
            className="btn btn-primary my-1"
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  // errors for emails
  errors.recipients = validateEmails(
    values.recipients || ""
  );

  _.each(formFields, ({ name }) => {
    if (!values[name]) {
      errors[
        name
      ] = `You must provide a ${name}`;
    }
  });

  return errors;
}

export default reduxForm({
  validate,
  form: "surveyForm",
  destroyOnUnmount: false, // keep data in memory
})(SurveyForm);
