import axios from "axios";
import {
  SURVEY_SENT,
  ADD_5,
  AUTH_ERROR,
  USER_LOADED,
  FETCH_SURVEYS,
  FETCH_SURVEY,
  FETCH_LATEST_SURVEYS,
} from "./types";
import backend from "./backend";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "./../reducers/asyncReducer";
import { delay } from "./../utils/util";
import { toast } from "react-toastify";

// GET SURVEYS
export function fetchSurveys() {
  return async function (dispatch) {
    dispatch(asyncActionStart());
    try {
      const res = await axios.get(
        `${backend}/api/surveys`
      );

      await delay(3000);
      dispatch({
        type: FETCH_SURVEYS,
        payload: res.data,
      });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
}

// GET ONE SURVEY
export const fetchSurvey = (
  id
) => async (dispatch) => {
  // console.log("fetchSurveys");
  dispatch(asyncActionStart());
  try {
    const res = await axios.get(
      `${backend}/api/surveys/${id}`
    );
    // console.log(res.data);
    // await delay(3000);
    dispatch({
      type: FETCH_SURVEY,
      payload: res.data,
    });

    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError(error));
  }
};

// GET 5 Latest SURVEYS
export const fetchFiveSurveys = () => async (
  dispatch
) => {
  dispatch(asyncActionStart());
  const res = await axios.get(
    `${backend}/api/surveys/all`
  );
  await delay(3000);

  dispatch({
    type: FETCH_LATEST_SURVEYS,
    payload: res.data,
  });
  dispatch(asyncActionFinish());
};

// GET SURVEYS
export const fetchSurveys_ = () => async (
  dispatch
) => {
  const res = await axios.get(
    `${backend}/api/surveys`
  );

  dispatch({
    type: FETCH_SURVEYS,
    payload: res.data,
  });
};

// POST SURVEY
export const submitSurvey = (
  values,
  history
) => async (dispatch) => {
  dispatch(asyncActionStart());
  try {
    const res = await axios.post(
      `${backend}/api/surveys`,
      values
    );
    await delay(3000);
    // console.log(res);
    dispatch({
      type: SURVEY_SENT,
      // payload is 'user'
      payload: "Survey Sent",
    });
    dispatch({
      type: USER_LOADED,
      // payload is 'user'
      payload: res.data,
    });

    dispatch(asyncActionFinish());
    toast.success(
      "Survey sent successfully."
    );
  } catch (error) {
    dispatch(asyncActionError());
    console.log(error);
    toast.error(
      "You need at least 1 credit to send a Survey"
    );
  }
  history.push("/");
};

// POST STRIPE
export const add5 = () => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${backend}/api/stripe/add5`
    );
    // console.log(res.data);
    dispatch({
      type: ADD_5,
      // payload is 'user'
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    console.log(err);
  }
};
