import React, {
  Component,
  Fragment,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import {
  Link,
  withRouter,
} from "react-router-dom";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { fetchSurvey } from "../../actions/survey";
import Spinner from "../../components/layout/Spinner";
import LoadingComponent from "./../layout/Loading";
import asyncReducer from "./../../reducers/asyncReducer";
import survey from "../../reducers/survey";

import SurveyChart from "./SurveyChart";

const VotedSurvey = ({
  fetchSurvey,
  loading,
  asyncReducer,
  survey,
}) => {
  console.log(survey);
  const {
    surveyId,
    yes,
    no,
    vote,
  } = useParams();

  useEffect(() => {
    if (surveyId) {
      console.log(surveyId);
      fetchSurvey(surveyId);
    } else console.log("cur");
  }, [fetchSurvey, surveyId]);
  //   componentDidMount() {
  //     if (!this.props.loading) {
  //       this.props.fetchSurveys();
  //       console.log(this.props.surveys);
  //     }
  //   }

  function getDaysAgoFormat(
    dateSentLast
  ) {
    var today = new Date().getDate();
    console.log(new Date().getMonth());
    var sentLastDate = new Date(
      dateSentLast
    ).getDate();

    // var calculatedDifference = 0;
    if (today - sentLastDate === 0) {
      return "today.";
    } else {
      return `${
        today - sentLastDate
      } days ago.`;
    }
  }

  function getSentDateFormat(dateSent) {
    var year = new Date(
      dateSent
    ).getFullYear();
    var month =
      new Date(dateSent).getMonth() + 1;
    console.log(month);
    var day = new Date(
      dateSent
    ).getDate();
    return (
      day + "/" + month + "/" + year
    );
  }

  function loadSurvey() {
    if (survey.loading) {
      return (
        <>
          {/* <SurveyChart /> */}

          <LoadingComponent />
        </>
      );
    } else
      return (
        <>
          <div className="content-survey">
            <span>
              <h5>
                <i>Question:</i>
                <br />{" "}
                <span className="color-primary-title">
                  {
                    survey.currentSurvey
                      .subject
                  }
                </span>
              </h5>
              <br></br>
              <strong className="color-primary">
                {survey.currentSurvey
                  .yes +
                  survey.currentSurvey
                    .no}
              </strong>
              &nbsp;Votes casted to this
              day since&nbsp;
              <strong className="color-primary">
                {getSentDateFormat(
                  survey.currentSurvey
                    .dateSent
                )}
              </strong>
            </span>
            <SurveyChart
              currentSurvey={
                survey.currentSurvey
              }
            />
            <span>
              Last vote has been
              cast&nbsp;
              <strong className="color-primary">
                {getDaysAgoFormat(
                  survey.currentSurvey
                    .lastResponded
                )}
              </strong>
            </span>
          </div>
        </>
      );
  }

  return (
    <div
      style={{ alignItems: "center" }}
    >
      {vote !== "status" ? (
        <h4>Thank you for voting!</h4>
      ) : (
        <br />
      )}
      Here are the results of the survey
      so far:
      <br></br>
      <hr></hr>
      {loadSurvey()}
      <br />
      <hr />
      <div className="button-right">
        <Link to="/">
          <button
            right
            className="btn btn-info color-red"
          >
            See all surveys
          </button>
        </Link>
        <br />
        &nbsp;
        <br />
      </div>
    </div>
  );
};

function mapStateToProps({
  survey,
  loading,
  auth,
  asyncReducer,
}) {
  return { survey, loading, auth };
}

export default connect(
  mapStateToProps,
  { fetchSurvey }
)(VotedSurvey);
