import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import DashboardEmaily from "../DashboardEmaily";
import SurveyList from "../surveys/SurveyList";
import LoadingComponent from "./Loading";
import { fetchFiveSurveys } from "./../../actions/survey";
import LatestSurveys from "../surveys/LatestSurveys";

const Landing = ({
  auth: { isAuthenticated, loading },
  logout,
  surveys,
}) => {
  const loginButtons = (
    <>
      <div className="loading-content">
        <h1 className="x-large">
          Emaily
        </h1>
        <p className="lead">
          Create a survey, ask a
          question and send it to a list
          of emails.
        </p>
        <LatestSurveys />
        <p className="lead">
          Signup or Signin to create
          your own surveys.
        </p>
        <div className="buttons">
          <Link
            to="/register"
            className="btn btn-primary"
          >
            Sign Up
          </Link>
          <Link
            to="/login"
            className="btn btn-light"
          >
            Login
          </Link>
        </div>
      </div>
      {/* <DashboardEmaily /> */}
    </>
  );

  const contentLoaded = (
    <>
      <h3 className="subtitle">
        Start using Emaily. Send a new
        Survey!
      </h3>
      <SurveyList />
      <DashboardEmaily />
    </>
  );

  return (
    <section className="landing ">
      <div className="dark-overlay">
        <div className="landing-inner ">
          {!loading ? (
            <Fragment>
              {isAuthenticated
                ? contentLoaded
                : loginButtons}{" "}
            </Fragment>
          ) : (
            <div className="loading-auth">
              <h4>
                <i>
                  Checking
                  authentification
                  status...
                </i>
              </h4>

              <LoadingComponent
                small={true}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

Landing.propTypes = {
  // ptfr
  logout: PropTypes.func.isRequired,
  // ptor
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  surveys: state.surveys,
});

export default connect(
  mapStateToProps,
  { logout, fetchFiveSurveys }
)(Landing);
