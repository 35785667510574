import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_PROFILE,
  PROFILE_ERROR,
  UPDATE_PROFILE,
  ACCOUNT_DELETED,
  CLEAR_PROFILE,
  GET_PROFILES,
  GET_REPOS,
} from "./types";
import backend from "./backend";

// Get current user profile
export const getCurrentProfile = () => async (
  dispatch
) => {
  try {
    const res = await axios.get(
      `${backend}/api/profile/me`
    );

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get All profiles
export const getProfiles = () => async (
  dispatch
) => {
  dispatch({ type: CLEAR_PROFILE });
  try {
    const res = await axios.get(
      `${backend}/api/profile`
    );

    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get profile by ID
export const getProfileById = (
  userId
) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${backend}/api/profile/user/${userId}`
    );

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get github repos
export const getGithubRepos = (
  username
) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${backend}/api/profile/github/${username}`
    );

    dispatch({
      type: GET_REPOS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Create or update a profile
export const createProfile = (
  formData,
  history,
  edit = false
) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type":
          "application/json",
      },
    };

    const res = await axios.post(
      `${backend}/api/profile`,
      formData,
      config
    );
    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });

    dispatch(
      setAlert(
        edit
          ? "Profile Updated"
          : "Profile Created",
        "success"
      )
    );

    if (!edit) {
      history.push("/dashboard");
    }
  } catch (err) {
    const errors =
      err.response.data.errors;

    if (errors) {
      errors.forEach((error) =>
        dispatch(
          setAlert(error.msg, "danger")
        )
      );
    }
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Add Experience
export const addExperience = (
  formData,
  history
) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type":
          "application/json",
      },
    };

    const res = await axios.put(
      `${backend}/api/profile/experience`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    dispatch(
      setAlert(
        "Experience Added",
        "success"
      )
    );

    history.push("/dashboard");
  } catch (err) {
    const errors =
      err.response.data.errors;

    if (errors) {
      errors.forEach((error) =>
        dispatch(
          setAlert(error.msg, "danger")
        )
      );
    }
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Add Education
export const addEducation = (
  formData,
  history
) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type":
          "application/json",
      },
    };

    const res = await axios.put(
      `${backend}/api/profile/education`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    dispatch(
      setAlert(
        "Education Added",
        "success"
      )
    );

    history.push("/dashboard");
  } catch (err) {
    const errors =
      err.response.data.errors;

    if (errors) {
      errors.forEach((error) =>
        dispatch(
          setAlert(error.msg, "danger")
        )
      );
    }
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Delete Experience
export const deleteExperience = (
  id
) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${backend}/api/profile/experience/${id}`
    );
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    dispatch(
      setAlert(
        "Experience removed",
        "success"
      )
    );
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Delete Education
export const deleteEducation = (
  id
) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `${backend}/api/profile/education/${id}`
    );
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });

    dispatch(
      setAlert(
        "Education removed",
        "success"
      )
    );
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Delete Account & profile
export const deleteAccount = () => async (
  dispatch
) => {
  if (
    window.confirm(
      "Are you sure ? this cannot be undone."
    )
  ) {
    try {
      await axios.delete(
        `${backend}/api/profile`
      );
      dispatch({
        type: CLEAR_PROFILE,
      });
      dispatch({
        type: ACCOUNT_DELETED,
      });

      dispatch(
        setAlert(
          "Your account has been permanently deleted"
        )
      );
    } catch (err) {
      dispatch({
        type: PROFILE_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

export const handleStripeToken = token => async dispatch => {
  
  const res = await axios.post(`${backend}/api/stripe`, token);
  // try {
  //   const res = await axios.post(`${backend}/api/stripe`, token);
  //   dispatch({
  //     type: GET_PROFILE,
  //     payload: res.data,
  //   });
  // } catch (err) {
  //   dispatch({
  //     type: PROFILE_ERROR,
  //     payload: {
  //       msg: err.response.statusText,
  //       status: err.response.status,
  //     },
  //   });
  // }
  
}
