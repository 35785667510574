import React, {
  Fragment,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCurrentProfile,
  deleteAccount,
} from "../../actions/profile";
import DashboardActions from "./DashboardActions";
import Experience from "./Experience";
import Education from "./Education";
import Spinner from "../layout/Spinner";

const Dashboard = ({
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  profile: { profile, loading },
}) => {
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary">
        Dashboard
      </h1>
      <p className="lead">
        <i className="fas fa-user" />
        Welcome {user && user.name}
      </p>
      {profile !== null ? (
        <Fragment>
          <DashboardActions />
          <Experience
            experience={
              profile.experience
            }
          />
          <Education
            education={
              profile.education
            }
          />

          <div className="my-">
            <button
              className="btn btn-danger"
              onClick={() =>
                deleteAccount()
              }
            >
              <i className="fas fa-user-minus" />{" "}
              Delete my account
            </button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <p>
            You have not yet setup a
            profile, please add some
            info
          </p>
          <Link
            to="/create-profile"
            className="btn btn-primary my-1"
          >
            Create profile
          </Link>
        </Fragment>
      )}
    </Fragment>
  );
};

Dashboard.propTypes = {
  // ptfr
  getCurrentProfile:
    PropTypes.func.isRequired,
  // ptor
  auth: PropTypes.object.isRequired,
  // ptor
  profile: PropTypes.object.isRequired,
  deleteAccount:
    PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, deleteAccount }
)(Dashboard);
