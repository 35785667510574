// Survey Form review
import React from "react";
import { connect } from "react-redux";
import formFields from "./formFields";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { submitSurvey } from "./../../actions/survey";
import LoadingComponent from "../layout/Loading";
import asyncReducer from "./../../reducers/asyncReducer";

const SurveyFormReview = ({
  onCancel,
  formValues,
  submitSurvey,
  history,
  loading,
}) => {
  const reviewFields = _.map(
    formFields,
    (field) => {
      return (
        <div key={field.label}>
          <div>
            <strong className="green-color">
              {field.label}: &nbsp;
            </strong>{" "}
            {formValues[field.name]}
          </div>
          <hr />
        </div>
      );
    }
  );

  return (
    <div className="container login-class">
      <h4 className="subtitle">
        Please confirm your entries
      </h4>
      <hr />

      <div>{reviewFields}</div>
      <button
        className="btn btn-secondary my-1"
        onClick={onCancel}
      >
        Back / Edit
      </button>
      {loading ? (
        <>
          <LoadingComponent
            small={true}
          />
        </>
      ) : (
        <button
          className="btn btn-success my-1"
          onClick={() =>
            submitSurvey(
              formValues,
              history
            )
          }
        >
          Send Survey
        </button>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    formValues:
      state.form.surveyForm.values,
    loading: state.asyncReducer.loading,
  }; // redux-form
}

export default connect(
  mapStateToProps,
  { submitSurvey }
)(withRouter(SurveyFormReview));
