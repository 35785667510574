import React from "react";

export default ({
  input,
  label,
  meta: { error, touched },
}) => {
  // console.log(meta);

  return (
    <div className="form-group">
      {/* <label>{label}</label> */}

      <input
        placeholder={label}
        {...input}
        className="form-input"
      />
      <span className="error-form">
        {touched && error}
      </span>
    </div>
  );
};
