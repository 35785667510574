// email@email.com, _invalid@email.com, good2@email.com
// http://emailregex.com/

// Javascript
// const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// HTML 5
const regularExpression = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default (emails) => {
  const invalidEmailsWithoutBlanks = emails
    .split(",")
    // remove the empty spaces
    .map((email) => email.trim())
    // filter the invalid emails
    .filter((email) => email !== "");
  const invalidEmails = invalidEmailsWithoutBlanks.filter(
    (email) =>
      regularExpression.test(email) ===
      false
  );

  if (invalidEmails.length) {
    return `These emails are invalid: ${invalidEmails}`;
  } else {
    return;
  }
};
